import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PersonalInfo from '../components/about/PersonalInfo';
import Resume from '../components/about/Resume';
import Social from '../components/about/Social';
import Service from '../components/Service';
// import Contact from '../components/Contact';
// import Address from '../components/Address';
import Header from '../components/Header';
// import Portfolio from '../components/Portfolio';
// import Testimonial from '../components/Testimonial';
// import Blog from '../components/Blog';

const tabMenuList = [
  { icon: 'icon-archive', title: 'Über mich' },
  { icon: 'icon-contacs', title: 'Erfahrung' },
  { icon: 'icon-gear', title: 'Kenntnisse' },
  // { icon: 'icon-letter', title: 'Kontakt' },
  // { icon: 'icon-briefcase', title: 'Portfolio' },
  // { icon: 'icon-message', title: 'Testimonial' },
  // { icon: 'icon-writing', title: 'Blog' },
];

const Main = () => {
  document.body.classList.add('light');
  return (
    <>
      {/* <!-- BACKGROUND --> */}
      <div
        className="fixed_background movingEffect"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + 'img/hero/background.jpg'
          })`,
        }}
      ></div>
      {/* <!-- /BACKGROUND --> */}

      {/* <!-- CONTENT --> */}
      <div className="fixed_content">
        {/* <!-- TOPBAR --> */}
        <div className="topbar">
          <div className="container">
            <Header />
          </div>
        </div>
        {/* <!-- /TOPBAR --> */}

        <div className="container">
          <Tabs>
            <div className="fixed_content_inner">
              <div className="content_left">
                {/* <!-- MENUBAR --> */}
                <div className="menubar">
                  <TabList>
                    {tabMenuList.map((menu, i) => (
                      <Tab key={i}>
                        <div className={`svg ${menu.icon}`}></div>
                        <span>{menu.title}</span>
                      </Tab>
                    ))}
                  </TabList>
                </div>
                {/* <!-- /MENUBAR --> */}

                {/* <!-- INFOBAR --> */}
                <div className="infobar">
                  <div className="bar_in">
                    <div className="image">
                      <img src="img/thumbs/10-9.jpg" alt="" />
                      <div
                        className="main"
                        style={{
                          backgroundImage: `url(${
                            process.env.PUBLIC_URL + 'img/hero/3.jpg'
                          })`,
                        }}
                      ></div>
                    </div>
                    <div className="extra">
                      <div className="short">
                        <h3>Alexander Liebisch</h3>
                        <p>IT-Consultant</p>
                      </div>
                      <div className="social">
                        <Social />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /INFOBAR --> */}
              </div>
              {/* End .content Left */}

              <div className="content_right">
                <div className="mainbar">
                  <div className="main_section">
                    <TabPanel>
                      <PersonalInfo />
                    </TabPanel>
                  </div>
                  {/* End  .main_section */}

                  <div className="main_section">
                    <TabPanel>
                      <Resume />
                    </TabPanel>
                  </div>
                  {/* End  .main_section */}

                  {/* <div className="main_section">
                    <TabPanel>
                      <Portfolio />
                    </TabPanel>
                  </div> */}
                  {/* End  .main_section */}

                  <div className="main_section">
                    <TabPanel>
                      <Service />
                    </TabPanel>
                  </div>
                  {/* End  .main_section */}

                  {/* <!-- TESTIMONIALS --> */}
                  {/* <div className="main_section">
                    <TabPanel>
                      <div className="testimonials" data-aos="fade-right">
                        <div className="main_title">
                          <span>Testimonials</span>
                          <h3>What people say about me</h3>
                        </div>
                        <div className="testimonials_list">
                          <Testimonial />
                        </div>
                      </div>
                    </TabPanel>
                  </div> */}

                  {/* <!-- NEWS/BLOG --> */}
                  {/* <div className="main_section">
                    <TabPanel>
                      <div className="news" data-aos="fade-right">
                        <div className="main_title">
                          <span>Blog</span>
                          <h3>Latest tips, tricks &amp; Updates</h3>
                        </div>
                        <Blog />
                      </div>
                    </TabPanel>
                  </div> */}

                  {/* <!-- CONTACT --> */}
                  {/* <div className="main_section">
                    <TabPanel>
                      <div className="contact" data-aos="fade-right">
                        <div className="main_title">
                          <span>Kontakt</span>
                          <p>
                            Bist du bereit, loszulegen? Melde dich für ein
                            persönliches Gespräch!
                          </p>
                        </div>
                        <div className="wrapper">
                          <div className="left">
                            <Address />
                          </div> 
                          <div className="right">
                            <div className="fields">
                              <Contact />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div> */}
                  {/* End  .main_section */}
                </div>
              </div>
              {/* End .content Right */}
            </div>
          </Tabs>
        </div>
      </div>
      {/* <!-- /CONTENT --> */}
    </>
  );
};

export default Main;
