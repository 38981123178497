import React, { useState } from 'react';
import Modal from 'react-modal';
import ModalFour from './serviceModals/ModalFour';
import ModalOne from './serviceModals/ModalOne';
import ModalThree from './serviceModals/ModalThree';
import ModalTwo from './serviceModals/ModalTwo';
import ModalFive from './serviceModals/ModalFive';
import ModalSix from './serviceModals/ModalSix';

Modal.setAppElement('#root');

const Service = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }
  function toggleModalFive() {
    setIsOpen5(!isOpen5);
  }
  function toggleModalSix() {
    setIsOpen6(!isOpen6);
  }

  return (
    <div className="services" data-aos="fade-right">
      <div className="main_title">
        <span>Kenntnisse</span>
        <p>
          Durch meine Tätigkeit als IT-Berater bei Cassini Consulting AG und als
          Mitbegründer und CTO von Easy-Tutor habe ich umfangreiche Kenntnisse
          und Fähigkeiten in den Bereichen digitale Transformation,
          Unternehmensentwicklung, Customer Experience, Marketing und
          Teammanagement erworben.
        </p>
      </div>
      {/* End .main_title */}

      <div className="service_list">
        <ul>
          <li>
            {/* <div className="list_inner" onClick={toggleModalThree}> */}
            <div className="list_inner">
              <span className="icon">
                <span className="icon-briefcase svg"></span>
              </span>
              <h3 className="title">Strategische Beratung & Management</h3>
              <p className="text">
                Digitale Transformation, Unternehmensentwicklung
              </p>
            </div>
          </li>

          <li>
            {/* <div className="list_inner" onClick={toggleModalThree}> */}
            <div className="list_inner">
              <span className="icon">
                <span className="icon-squares svg"></span>
              </span>
              <h3 className="title">Projektmanagement</h3>
              <p className="text">
                Agile & Scrum Methoden, Risikomanagement,
                Stakeholder-Kommunikation
              </p>
            </div>
          </li>

          <li>
            {/* <div className="list_inner" onClick={toggleModalThree}> */}
            <div className="list_inner">
              <span className="icon">
                <span className="icon-writing_2 svg"></span>
              </span>
              <h3 className="title">Content Management</h3>
              <p className="text">Wordpress, Strapi, Ghost Blog</p>
            </div>
          </li>

          <li>
            {/* <div className="list_inner" onClick={toggleModalThree}> */}
            <div className="list_inner">
              <span className="icon">
                <span className="icon-web svg"></span>
              </span>
              <h3 className="title">Shop & Payments</h3>
              <p className="text">
                Woocommerce, Stripe, Mollie, Billomat, Novalnet
              </p>
            </div>
          </li>

          <li>
            {/* <div className="list_inner" onClick={toggleModalThree}> */}
            <div className="list_inner">
              <span className="icon">
                <span className="icon-user svg"></span>
              </span>
              <h3 className="title">CRM & Marketing</h3>
              <p className="text">
                Salesforce Sales Cloud & Marketing Cloud, ActiveCampaign,
                Pipedrive, Aircall
              </p>
            </div>
          </li>

          <li>
            {/* <div className="list_inner" onClick={toggleModalThree}> */}
            <div className="list_inner">
              <span className="icon">
                <span className="icon-globe svg"></span>
              </span>
              <h3 className="title">Online Advertising</h3>
              <p className="text">
                Google Marketing Platform: Ads & Analytics (UA & GA4) & Optimize
                & Tag Manager, Facebook Ads, Taboola, Outbrain, Pinterest
              </p>
            </div>
          </li>

          <li>
            {/* <div className="list_inner" onClick={toggleModalOne}> */}
            <div className="list_inner">
              <span className="icon">
                <span className="icon-physics svg"></span>
              </span>
              <h3 className="title">Development & Frameworks</h3>
              <p className="text">
                HTML, CSS, Javascript, PHP, Node.js, Koa, Vue.js, Nuxt.js,
                MySQL, MongoDB, Sentry, Solidity, Github
              </p>
            </div>
          </li>

          <li>
            {/* <div className="list_inner" onClick={toggleModalOne}> */}
            <div className="list_inner">
              <span className="icon">
                <span className="icon-gear svg"></span>
              </span>
              <h3 className="title">Cloud Services & DevOps</h3>
              <p className="text">
                Atlassian: Jira & Confluence Administration, AWS: EC2, ECS, RDS,
                CDK, Cloudwatch, Route 53
              </p>
            </div>
          </li>
        </ul>
      </div>

      {/* Start Modal Motion Graphy */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="modalbox ">
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="description_wrap ">
              <ModalOne />
            </div>
          </div>
          {/* End box inner */}
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End Modal Motion Graphy */}

      {/* Start Modal Branding & Design */}
      <Modal
        isOpen={isOpen2}
        onRequestClose={toggleModalTwo}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="modalbox ">
          <button className="close-modal" onClick={toggleModalTwo}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="description_wrap ">
              <ModalTwo />
            </div>
          </div>
          {/* End box inner */}
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End Modal Branding & Design */}

      {/* Start Modal Mobile App Design */}
      <Modal
        isOpen={isOpen3}
        onRequestClose={toggleModalThree}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="modalbox ">
          <button className="close-modal" onClick={toggleModalThree}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="description_wrap ">
              <ModalThree />
            </div>
          </div>
          {/* End box inner */}
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End Modal Mobile App Design*/}

      {/* Start Modal Modal Ui/Ux Consultancy */}
      <Modal
        isOpen={isOpen4}
        onRequestClose={toggleModalFour}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="modalbox ">
          <button className="close-modal" onClick={toggleModalFour}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="description_wrap">
              <ModalFour />
            </div>
          </div>
          {/* End box inner */}
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End Modal Ui/Ux Consultancy*/}
      {/* Start Modal Modal Ui/Ux Consultancy */}
      <Modal
        isOpen={isOpen5}
        onRequestClose={toggleModalFive}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="modalbox ">
          <button className="close-modal" onClick={toggleModalFive}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="description_wrap">
              <ModalFive />
            </div>
          </div>
          {/* End box inner */}
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End Modal Ui/Ux Consultancy*/}
      {/* Start Modal Modal Ui/Ux Consultancy */}
      <Modal
        isOpen={isOpen6}
        onRequestClose={toggleModalSix}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="modalbox ">
          <button className="close-modal" onClick={toggleModalSix}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="description_wrap">
              <ModalSix />
            </div>
          </div>
          {/* End box inner */}
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End Modal Ui/Ux Consultancy*/}
    </div>
  );
};

export default Service;
