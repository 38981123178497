import React from 'react';
import Education from './Education';
import Experience from './Experience';
// import PersonalSkills from './PersonalSkills';
// import SoftwareSkills from './SoftwareSkills';

const Resume = () => {
  return (
    // <!-- RESUME -->
    <>
      <div className="resume" data-aos="fade-right">
        <div className="main_title">
          <span>Erfahrung</span>
          {/* <h3>Combination of Skill &amp; Experience</h3> */}
        </div>
        {/* End  .resume_title */}

        <div className="resume_inner">
          <div className="left">
            <div className="info_list">
              <div className="resume_title">
                <h3>Berufserfahrung</h3>
                <span className="shape"></span>
              </div>
              {/* End  .resume_title */}
              <Experience />
            </div>
            <div className="info_list">
              <div className="resume_title">
                <h3>Bildung</h3>
                <span className="shape"></span>
              </div>
              {/* End  .resume_title */}
              <Education />
            </div>
          </div>
          {/* End left */}

          {/* <div className="right">
            <div className="skills_list">
              <div className="resume_title">
                <h3>Personal Skills</h3>
                <span className="shape"></span>
              </div>
              <PersonalSkills />
            </div>

            <div className="skills_list">
              <div className="resume_title">
                <h3>Software Skills</h3>
                <span className="shape"></span>
              </div>
              <SoftwareSkills />
            </div>
          </div> */}
        </div>
      </div>
    </>
    // <!-- /RESUME -->
  );
};

export default Resume;
