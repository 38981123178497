import React from 'react';

const experienceContent = [
  {
    position: 'Consultant',
    companyName: 'Cassini Consulting AG',
    year: 'seit 2023',
    details: `IT-Beratung mit einem Schwerpunkt auf digitale Transformation für KMU und große Unternehmen.`,
  },
  {
    position: 'Web Developer, Digital Marketer',
    companyName: 'Freiberuflich',
    year: 'seit 2022',
    details: `Freiberufliche Arbeit in digitalen Projekten für Kunden wie zum Beispiel www.versicherungsvergleiche.de oder HACOY.`,
  },
  {
    position: 'Gründer & Geschäftsführer (CTO)',
    companyName: 'Easy-Tutor GmbH',
    year: '2017 - 2022',
    details: `Easy-Tutor ist eine der größten Online-Nachhilfeschulen im deutschsprachigen Raum. Schüler und deren Eltern können  qualifizierte und geprüfte Nachhilfelehrer finden und Online-Meetings für persönlichen Einzelunterricht buchen.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="list_inner">
            <div className="short">
              <div className="job">
                <h3>{val.companyName}</h3>
                <span>{val.position}</span>
              </div>
              <div className="year">
                <span>{val.year}</span>
              </div>
            </div>
            <div className="text">
              <p>{val.details}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
