import React, { useEffect } from 'react';
import Routes from './router/Routes';
import ScrollToTop from './components/ScrollToTop';
import AOS from 'aos';
import AnimatedCursor from 'react-animated-cursor';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);
  return (
    <div className="all_wrap">
      <AnimatedCursor
        innerSize={10}
        outerSize={25}
        color="28, 56, 121"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={1.2}
      />
      <ScrollToTop />
      <Routes />
    </div>
  );
};

export default App;
